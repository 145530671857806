import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Spinner
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isScopes } from "./../../services/Auth";
import { getDefaultOptionsPatrimony } from "./../../utils/Report";
import { mergeObject } from "./../../utils/Object";

import Item from "./Item";
import Option from "./../Option";

import PatrimonyService from "./../../services/Patrimony";

const Patrimony = ({
  props: { currentUser, periodFrequency, patrimoniesSearchSelected, periodDateEnd, periodDateStart, periodRoute, periodRouteFix, periodSpeed, speeding, virtualFencesSearchSelected },
  funcs: { addNotice, enableLoader, disableLoader }
}) => {
  const { t } = useTranslation();
  const patrimonyService = new PatrimonyService();
  const [running, setRunning] = useState(false);
  const [selectedReport, setSelectedReport] = useState({
    selected: false,
    options: false,
    values: false
  });

  const buttonDisabled = () => {
    const id = "reports-patrimony-delayed-location";
    if(selectedReport.selected === id) {
      if (patrimoniesSearchSelected.length > 0 && virtualFencesSearchSelected.length > 0) return false;
    }
    else {
      if (patrimoniesSearchSelected.length > 0) return false;
    }
    return true;
  };

  useEffect(() => {
    if(selectedReport.selected) {
      if(selectedReport.scoped(currentUser) === false) {
        changeSelectedReport(false);
      }
    }
  }, [currentUser]); /* eslint-disable-line */

  const changeSelectedReport = (item) => {
    setSelectedReport({
      selected: false,
      options: false,
      values: false,
      scoped: () => false
    });
    if(item !== false) {
      setTimeout(() => {
        const options = getDefaultOptionsPatrimony();
        item.options = mergeObject(options, item.options);
        setSelectedReport(item);
      }, 100);
    }
  };

  const getDateSelected = () => {
    return {
      endDate: periodDateEnd,
      frequency: periodFrequency,
      startDate: periodDateStart,
      route: periodRoute,
      routeFix: periodRouteFix,
      speed: periodSpeed,
      speeding
    };
  };

  const getPatrimoniesSearchSelected = () => {
    const patrimonies = [];
    patrimoniesSearchSelected.map(item => {
      return patrimonies.push(item.id);
    });
    return patrimonies;
  };

  const getVirtualFencesSearchSelected = () => {
    const virtualFences = [];
    virtualFencesSearchSelected.map(item => {
      return virtualFences.push(item.id);
    });
    return virtualFences;
  };

  const getReport = async (e) => {
    if(!running) {
      setRunning(true);
      enableLoader();
      const { endDate, startDate, frequency, route, routeFix: routeFixOp, speed, speeding: speedingControl } = getDateSelected();

      let endAt = undefined;
      if(selectedReport.options.dateEnd) {
        endAt = endDate;
      }

      let freqAt = undefined;
      if (selectedReport.options.filterFrequency) {
        freqAt = frequency;
      }
      if (selectedReport.values.id === "patrimony-positionaddress" && !selectedReport.options.filterFrequency) {
        freqAt = 5;
      }

      let routeAt = undefined;
      if (selectedReport.options.filterRoute) {
        routeAt = route;
      }

      let routeFix = undefined;
      if (selectedReport.options.filterRoute) {
        routeFix = routeFixOp;
      }

      let speedAt = undefined;
      if(selectedReport.options.filterFrequency) {
        speedAt = speed;
      }
      if (selectedReport.values.id === "patrimony-positionaddress" && !selectedReport.options.filterFrequency) {
        speedAt = false;
      }

      let startAt = undefined;
      if(selectedReport.options.dateStart) {
        startAt = startDate;
      }

      let speeding = undefined;
      if (selectedReport.options.filterSpeeding) {
        speeding = speedingControl;
      }

      const response = await patrimonyService.report({ endAt, freqAt, id: selectedReport.values.id, patrimonies: getPatrimoniesSearchSelected(), routeAt, routeFix, speedAt, speeding, startAt, token: currentUser.token, virtualFences: getVirtualFencesSearchSelected() });
      if(response.success) {
        if (response.data.alreadyRegistered) {
          addNotice({
            title: selectedReport.values.title,
            content: t("Error.Report.AlreadyRegistered")
          });
        }
        else {
          addNotice({
            title: selectedReport.values.title,
            content: t("Success.Report.Request")
          });
        }
      }
      else {
        addNotice({
          title: selectedReport.values.title,
          content: t("Error.Report.Request")
        });
      }
      changeSelectedReport(false);
      setTimeout(() => {
        disableLoader();
        setRunning(false);
      }, 100);
    }
  };

  const reports = [
    {
      id: "reports-patrimony-list",
      title: t("Title.Patrimony"),
      icon: <i className="fas fa-car" />,
      onClick: () => {
        const id = "reports-patrimony-list";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            filterMultiple: true
          },
          values: {
            id: "patrimony-list",
            title: t("Title.Patrimony")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:list"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-list",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:list"], every: false })
    },
    {
      id: "reports-patrimony-delayed-location",
      title: t("Title.DelayedLocation"),
      icon: <i className="fas fa-clock" />,
      onClick: () => {
        const id = "reports-patrimony-delayed-location";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            filterMultiple: true
          },
          values: {
            id: "patrimony-delayedlocation",
            title: t("Title.DelayedLocation")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:delayedLocation"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-delayed-location",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:delayedLocation"], every: false })
    },
    // {
    //   id: "reports-patrimony-downtime",
    //   title: t("Title.Downtime"),
    //   icon: <i className="fas fa-hourglass-half" />,
    //   onClick: () => {
    //     const id = "reports-patrimony-downtime";
    //     if(selectedReport.selected === id) {
    //       return setSelectedReport(false);
    //     }
    //     changeSelectedReport({
    //       selected: id,
    //       options: {
    //         dateEnd: true,
    //         dateStart: true
    //       },
    //       values: {
    //         id: "patrimony-downtime",
    //         title: t("Title.Downtime")
    //       },
    //       scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtime"], every: false })
    //     });
    //   },
    //   selected: () => selectedReport.selected === "reports-patrimony-downtime",
    //   scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtime"], every: false })
    // },
    // {
    //   id: "reports-patrimony-downtime-address",
    //   title: t("Title.DowntimeAddress"),
    //   icon: <i className="fas fa-hourglass-half" />,
    //   onClick: () => {
    //     const id = "reports-patrimony-downtime-address";
    //     if(selectedReport.selected === id) {
    //       return setSelectedReport(false);
    //     }
    //     changeSelectedReport({
    //       selected: id,
    //       options: {
    //         dateEnd: true,
    //         dateStart: true
    //       },
    //       values: {
    //         id: "patrimony-downtimeaddress",
    //         title: t("Title.DowntimeAddress")
    //       },
    //       scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtimeAddress"], every: false })
    //     });
    //   },
    //   selected: () => selectedReport.selected === "reports-patrimony-downtime-address",
    //   scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtimeAddress"], every: false })
    // },
    // {
    //   id: "reports-patrimony-downtime-place",
    //   title: t("Title.DowntimePlace"),
    //   icon: <i className="fas fa-hourglass-half" />,
    //   onClick: () => {
    //     const id = "reports-patrimony-downtime-place";
    //     if(selectedReport.selected === id) {
    //       return setSelectedReport(false);
    //     }
    //     changeSelectedReport({
    //       selected: id,
    //       options: {
    //         dateEnd: true,
    //         dateStart: true
    //       },
    //       values: {
    //         id: "patrimony-downtimeplace",
    //         title: t("Title.DowntimePlace")
    //       },
    //       scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtimePlace"], every: false })
    //     });
    //   },
    //   selected: () => selectedReport.selected === "reports-patrimony-downtime-place",
    //   scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtimePlace"], every: false })
    // },
    // {
    //   id: "reports-patrimony-downtime-point",
    //   title: t("Title.DowntimePoint"),
    //   icon: <i className="fas fa-map-location" />,
    //   onClick: () => {
    //     const id = "reports-patrimony-downtime-point";
    //     if(selectedReport.selected === id) {
    //       return setSelectedReport(false);
    //     }
    //     changeSelectedReport({
    //       selected: id,
    //       options: {
    //         dateEnd: true,
    //         dateStart: true
    //       },
    //       values: {
    //         id: "patrimony-downtimepoint",
    //         title: t("Title.DowntimePoint")
    //       },
    //       scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtimePoint"], every: false })
    //     });
    //   },
    //   selected: () => selectedReport.selected === "reports-patrimony-downtime-point",
    //   scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtimePoint"], every: false })
    // },
    // {
    //   id: "reports-patrimony-downtime-point-visit",
    //   title: t("Title.DowntimePointVisit"),
    //   icon: <i className="fas fa-map-location" />,
    //   onClick: () => {
    //     const id = "reports-patrimony-downtime-point-visit";
    //     if(selectedReport.selected === id) {
    //       return setSelectedReport(false);
    //     }
    //     changeSelectedReport({
    //       selected: id,
    //       options: {
    //         dateEnd: true,
    //         dateStart: true
    //       },
    //       values: {
    //         id: "patrimony-downtimepoint",
    //         title: t("Title.DowntimePointVisit")
    //       },
    //       scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtimePointVisit"], every: false })
    //     });
    //   },
    //   selected: () => selectedReport.selected === "reports-patrimony-downtime-point-visit",
    //   scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:downtimePointVisit"], every: false })
    // },
    // {
    //   id: "reports-patrimony-last-position",
    //   title: t("Title.LastPositions"),
    //   icon: <i className="fas fa-map-marker-alt" />,
    //   onClick: () => {
    //     const id = "reports-patrimony-last-position";
    //     if(selectedReport.selected === id) {
    //       return setSelectedReport(false);
    //     }
    //     changeSelectedReport({
    //       selected: id,
    //       options: {
    //         filterMultiple: true
    //       },
    //       values: {
    //         id: "patrimony-lastposition",
    //         title: t("Title.LastPositions")
    //       },
    //       scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:lastPosition"], every: false })
    //     });
    //   },
    //   selected: () => selectedReport.selected === "reports-patrimony-last-position",
    //   scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:lastPosition"], every: false })
    // },
    // {
    //   id: "reports-patrimony-last-position-simple",
    //   title: t("Title.LastPositions.Simple"),
    //   icon: <i className="fas fa-map-marker-alt" />,
    //   onClick: () => {
    //     const id = "reports-patrimony-last-position-simple";
    //     if(selectedReport.selected === id) {
    //       return setSelectedReport(false);
    //     }
    //     changeSelectedReport({
    //       selected: id,
    //       options: {
    //         filterMultiple: true
    //       },
    //       values: {
    //         id: "patrimony-lastpositionsimple",
    //         title: t("Title.LastPositions.Simple")
    //       },
    //       scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:lastPositionSimple"], every: false })
    //     });
    //   },
    //   selected: () => selectedReport.selected === "reports-patrimony-last-position-simple",
    //   scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:lastPositionSimple"], every: false })
    // },
    {
      id: "reports-patrimony-niple-exporters",
      title: t("Title.NipleExporters"),
      icon: <i className="fas fa-map-marker-alt" />,
      onClick: () => {
        const id = "reports-patrimony-niple-exporters";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterNiple: true,
						limitDays: isScopes({ currentUser, scopes: ["is:master"], every: false }) ? (365 * 20) : 31
          },
          values: {
            id: "patrimony-nipleexporters",
            title: t("Title.NipleExporters")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleExporters"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-niple-exporters",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleExporters"], every: false })
    },
    {
      id: "reports-patrimony-niple-operation",
      title: t("Title.NipleOperations"),
      icon: "C | D",
      onClick: () => {
        const id = "reports-patrimony-niple-operation";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterNiple: true,
						limitDays: isScopes({ currentUser, scopes: ["is:master"], every: false }) ? (365 * 20) : 31
          },
          values: {
            id: "patrimony-nipleoperation",
            title: t("Title.NipleOperations")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleOperation"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-niple-operation",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleOperation"], every: false })
    },
    {
      id: "reports-patrimony-niple-operation-address",
      title: t("Title.NipleOperationsAddress"),
      icon: "C | D",
      onClick: () => {
        const id = "reports-patrimony-niple-operation-address";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterNiple: true,
						limitDays: isScopes({ currentUser, scopes: ["is:master"], every: false }) ? (365 * 20) : (31 * 6)
          },
          values: {
            id: "patrimony-nipleoperationaddress",
            title: t("Title.NipleOperationsAddress")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleOperationAddress"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-niple-operation-address",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleOperationAddress"], every: false })
    },
    {
      id: "reports-patrimony-hatch-open",
      title: t("Title.HatchOpen"),
      icon: "H",
      onClick: () => {
        const id = "reports-patrimony-hatch-open";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterNiple: true,
            limitDays: isScopes({ currentUser, scopes: ["is:master"], every: false }) ? (365 * 20) : 31
          },
          values: {
            id: "patrimony-hatchopen",
            title: t("Title.HatchOpen")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:hatchOpen"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-hatch-open",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:hatchOpen"], every: false })
    },
    {
      id: "reports-patrimony-hatch-open-address",
      title: t("Title.HatchOpenAddress"),
      icon: "H",
      onClick: () => {
        const id = "reports-patrimony-hatch-open-address";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterNiple: true,
            limitDays: isScopes({ currentUser, scopes: ["is:master"], every: false }) ? (365 * 20) : 31
          },
          values: {
            id: "patrimony-hatchopenaddress",
            title: t("Title.HatchOpenAddress")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:hatchOpenAddress"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-hatch-open-address",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:hatchOpenAddress"], every: false })
    },
    {
      id: "reports-patrimony-maintenance",
      title: t("Title.NipleMaintenances"),
      icon: "M",
      onClick: () => {
        const id = "reports-patrimony-maintenance";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterNiple: true,
            limitDays: (365 * 20)
          },
          values: {
            id: "patrimony-maintenance",
            title: t("Title.NipleMaintenances")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleMaintenance"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-maintenance",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleMaintenance"], every: false })
    },
    {
      id: "reports-patrimony-position",
      title: t("Title.Positions"),
      icon: <i className="fas fa-map-marker-alt" />,
      onClick: () => {
        const id = "reports-patrimony-position";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterFrequency: true,
            filterRoute: true,
            limitDays: 62
          },
          values: {
            id: "patrimony-position",
            title: t("Title.Positions")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:position"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-position",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:position"], every: false })
    },
    {
      id: "reports-patrimony-position-address",
      title: t("Title.PositionsAddress"),
      icon: <i className="fas fa-map-marker-alt" />,
      onClick: () => {
        const id = "reports-patrimony-position-address";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterFrequency: isScopes({ currentUser, scopes: ["is:master"], every: false }) ? true : (isScopes({ currentUser, scopes: ["reports:patrimonies:positionAddressTimed" ], every: false }) ? false : true),
            filterRoute: true,
            limitDays: 62
          },
          values: {
            id: "patrimony-positionaddress",
            title: t("Title.PositionsAddress")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:positionAddress"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-position-address",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:positionAddress"], every: false })
    },
    {
      id: "reports-patrimony-position-journey",
      title: t("Title.PositionsJourney"),
      icon: <i className="fas fa-map-marker-alt" />,
      onClick: () => {
        const id = "reports-patrimony-position-journey";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateStart: isScopes({ currentUser, scopes: ["is:master"], every: false }) ? true : false,
            filterFrequency: true,
            filterRoute: true,
            limitDays: 31
          },
          values: {
            id: "patrimony-positionjourney",
            title: t("Title.PositionsJourney")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:positionJourney"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-position-journey",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:positionJourney"], every: false })
    },
    {
      id: "reports-patrimony-journey-time",
      title: t("Title.JourneyTime"),
      icon: <i className="fas fa-car" />,
      onClick: () => {
        const id = "reports-patrimony-journey-time";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            limitDays: (365 * 20)
          },
          values: {
            id: "patrimony-journeytime",
            title: t("Title.JourneyTime")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:journeyTime"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-journey-time",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:journeyTime"], every: false })
    },
    {
      id: "reports-patrimony-journey-time-simple",
      title: t("Title.JourneyTime.Simple"),
      icon: <i className="fas fa-car" />,
      onClick: () => {
        const id = "reports-patrimony-journey-time-simple";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            limitDays: (365 * 20)
          },
          values: {
            id: "patrimony-journeytimesimple",
            title: t("Title.JourneyTime.Simple")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:journeyTimeSimple"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-journey-time-simple",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:journeyTimeSimple"], every: false })
    },
    // {
    //   id: "reports-patrimony-niple-failures",
    //   title: t("Title.Niple.Failures"),
    //   icon: <i className="fas fa-bug" />,
    //   onClick: () => {
    //     const id = "reports-patrimony-niple-failures";
    //     if(selectedReport.selected === id) {
    //       return setSelectedReport(false);
    //     }
    //     changeSelectedReport({
    //       selected: id,
    //       options: {
    //         dateEnd: true,
    //         dateStart: true,
    //         filterNiple: true,
    //         filterMultiple: true
    //       },
    //       values: {
    //         id: "patrimony-niplefailures",
    //         title: t("Title.Niple.Failures")
    //       },
    //       scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleFailures"], every: false })
    //     });
    //   },
    //   selected: () => selectedReport.selected === "reports-patrimony-niple-failures",
    //   scoped: () => false
    //   //isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:nipleFailures"], every: false })
    // },
    {
      id: "reports-patrimony-positionuse",
      title: "Períodos de utilização",
      icon: <i className="fas fa-map-marker-alt" />,
      onClick: () => {
        const id = "reports-patrimony-positionuse";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true
          },
          values: {
            id: "patrimony-positionuse",
            title: "Períodos de utilização"
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:positionUse"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-positionuse",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:positionUse"], every: false })
    },
    {
      id: "reports-patrimony-pump",
      title: t("Title.Pump"),
      icon: <i className="fas fa-tint" />,
      onClick: () => {
        const id = "reports-patrimony-pump";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterPump: true,
            limitDays: 31
          },
          values: {
            id: "patrimony-pump",
            title: t("Title.Pump")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:pump"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-pump",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:pump"], every: false })
    },
    {
      id: "reports-patrimony-speeding",
      title: t("Title.Speeding"),
      icon: <i className="fas fa-tachometer-alt" />,
      onClick: () => {
        const id = "reports-patrimony-speeding";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterSpeeding: true,
            limitDays: 31
          },
          values: {
            id: "patrimony-speeding",
            title: t("Title.Speeding")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:speeding"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-speeding",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:speeding"], every: false })
    },
    {
      id: "reports-patrimony-virtualfences",
      title: t("Title.VirtualFences"),
      icon: <i className="fas fa-sign" />,
      onClick: () => {
        const id = "reports-patrimony-virtualfences";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterMultipleVirtualFence: true,
            filterVirtualFence: true
          },
          values: {
            id: "patrimony-virtualfences",
            title: t("Title.VirtualFences")
          },
          scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:virtualFences"], every: false })
        });
      },
      selected: () => selectedReport.selected === "reports-patrimony-virtualfences",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:patrimonies:virtualFences"], every: false })
    }
  ];

  return (
    <div className="patrimony-reports">
      <Card>
        <Card.Header>{t("Title.Reports")}</Card.Header>
        <Card.Body>
          <div className="patrimony-reports-all">
            {
              <ul className="patrimony-reports-lists">
                {
                  reports.map(report => {
                    return report.scoped() ? (
                      <Item key={report.id} {...report} onClick={report.onClick} />
                    ) : null;
                  })
                }
              </ul>
            }
          </div>
        </Card.Body>
      </Card>
      <div className="patrimony-reports-options">
        {
          selectedReport.selected ? (
            <>
              <Option {...selectedReport} />
              <Form.Group className="default-form-button">
                <Button
                  variant="dark"
                  type="button"
                  disabled={buttonDisabled()}
                  onClick={getReport}
                >
                  {running ? <Spinner animation="border" size="sm" /> : t("Button.Generate")}
                </Button>
              </Form.Group>
            </>
          ) : (
            <h3 className="patrimony-reports-not-selected">{t("Title.Reports.NotSelected")}</h3>
          )
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    periodFrequency: state.frequency.periodFrequency,
    patrimoniesSearchSelected: state.patrimonies.patrimoniesSearchSelected,
    periodDateEnd: state.period.periodDateEnd,
    periodDateStart: state.period.periodDateStart,
    periodRoute: state.route.periodRoute,
    periodRouteFix: state.route.periodRouteFix,
    periodSpeed: state.frequency.periodSpeed,
    speeding: state.speeding.speeding,
    virtualFencesSearchSelected: state.virtualFences.virtualFencesSearchSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Patrimony));
