import React from "react";

import FilterDate from "./../../components/FilterDate";
import FilterFrequency from "./../../components/FilterFrequency";
import FilterPatrimony from "./../../components/FilterPatrimony";
import FilterRoute from "./../../components/FilterRoute";
import FilterSpeeding from "./../../components/FilterSpeeding";
import FilterUser from "./../../components/FilterUser";
import FilterVirtualFence from "./../../components/FilterVirtualFence";

const Option = ({ options }) => {
  const {
    dateEnd,
    dateStart,
    limitDays,
    filterActive,
    filterFrequency,
    filterMultiple,
    filterMultipleVirtualFence,
    filterNiple,
    filterPatrimony,
    filterPump,
    filterPreSelected,
    filterRoute,
    filterSpeeding,
    filterUser,
    filterVirtualFence
  } = options;

  const renderFilterDate = () => {
    if(dateEnd || dateStart) {
      return <FilterDate options={{ dateEnd, dateStart, limitDays: limitDays ?? 7 }} />;
    }
    return null;
  };

  const renderFilterFrequency = () => {
    if (filterFrequency) {
      return <FilterFrequency />;
    }
    return null;
  };

  const renderFilterPatrimony = () => {
    if(filterPatrimony) {
      return <FilterPatrimony options={{ filterActive, filterMultiple, filterNiple, filterPump, filterPreSelected }} />;
    }
    return null;
  };

  const renderFilterRoute = () => {
    if (filterRoute) {
      return <FilterRoute />;
    }
    return null;
  };

  const renderFilterSpeeding = () => {
    if (filterSpeeding) {
      return <FilterSpeeding />;
    }
    return null;
  };

  const renderFilterUser = () => {
    if(filterUser) {
      return <FilterUser options={{ filterMultiple }} />;
    }
    return null;
  };

  const renderFilterVirtualFence = () => {
    if(filterVirtualFence) {
      return <FilterVirtualFence options={{ filterActive, filterMultipleVirtualFence, filterPreSelected }} />;
    }
    return null;
  };

  return (
    <>
      {renderFilterSpeeding()}
      {renderFilterFrequency()}
      {renderFilterRoute()}
      {renderFilterDate()}
      {renderFilterPatrimony()}
      {renderFilterUser()}
      {renderFilterVirtualFence()}
    </>
  );
};

export default Option;
